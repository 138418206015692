import React, { useState, useEffect, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Global } from '@emotion/react'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import {
  StripeCheckoutRedirectContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
  ShapeContainer,
} from 'components/StripeCheckout'
import Loading from 'components/Loading'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'
import {
  Header,
  TitleContainer,
  UserContainer,
  InputBox,
  LoginPrompt,
} from 'components/RedeemComponents'

const PerpetualLicenseFWPage = ({
  redeemPath,
  vendorSourceName,
  queryParamKey,
  vendorName,
  language,
}) => {
  const SUBSCRIPTION_NAME = 'FluentWorlds Monthly'
  const REDIRECT_QUERY_PARAM = 'redirect'
  const AUTH0_LOCK_SIGN_UP = 'signUp'
  const ACCOUNT_PATH = '/account'

  const [vendorCodeInput, setVendorCodeInput] = useState('')
  const [auth0RedirectUri, setAuth0RedirectUri] = useState(
    process.env.GATSBY_WEBSITE_URL + redeemPath,
  )
  const [redirectLoading, setRedirectLoading] = useState(false)
  const {
    subscriptions,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    createStripeSubscription,
  } = useContext(SubscriptionContext)
  const { isAuthenticated, loginWithRedirect, user, auth0Loading } = useAuth0()
  const {
    username,
    fetchUserFromFirestore,
    userStateLoading,
    fetchUserProductAccess,
    productAccess,
  } = useContext(UserContext)
  const { t, ready } = useTranslation('perpetualLicenseFWRedeemPage')
  const [vendorCodeQueryParam] = useQueryParam(queryParamKey, StringParam)
  const [redirectQueryParam] = useQueryParam(REDIRECT_QUERY_PARAM, StringParam)
  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    const isUserAuthenticated = !auth0Loading && isAuthenticated

    if (isUserAuthenticated && !userStateLoading && user && username === '') {
      fetchUserFromFirestore()
    }
  }, [
    auth0Loading,
    userStateLoading,
    isAuthenticated,
    user,
    username,
    fetchUserFromFirestore,
  ])

  // when the user is logged in and subscriptions haven't yet been fetched then fetch the available site plans
  useEffect(() => {
    const isUserAuthenticated = !auth0Loading && isAuthenticated

    if (
      isUserAuthenticated &&
      !subscriptionStateLoading &&
      subscriptions &&
      subscriptions.length === 0
    ) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    fetchUserProductAccess,
    isAuthenticated,
    productAccess,
    subscriptions,
  ])

  // Try to create a subscription if the user provides correct query parameter
  useEffect(() => {
    if (vendorCodeQueryParam === '') {
      return toast.error(t('invalidCode', { vendor: vendorName }))
    }

    if (vendorCodeQueryParam !== undefined && vendorCodeQueryParam !== '') {
      setAuth0RedirectUri(
        auth0RedirectUri +
          '?' +
          queryParamKey +
          '=' +
          vendorCodeQueryParam +
          '&' +
          REDIRECT_QUERY_PARAM +
          '=true',
      )
    }

    // if we are going to attempt to redirect the user then keep use redirectLoading to keep the
    // input form from showing while calling async functions
    if (redirectQueryParam !== undefined && redirectQueryParam === 'true') {
      setRedirectLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // if we've retrieved courseAccess and subscriptions from async functions then we can set
    // redirectLoading to true so we can call the async create subscription process.
    // we use the redirectLoading so we don't call function more than once and also put app in loading state.
    // And check that we should not set loading if using no code in vendorCodeQueryParam
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      productAccess &&
      !productAccess.fluentworlds &&
      !redirectLoading &&
      vendorCodeQueryParam !== undefined &&
      vendorCodeQueryParam !== ''
    ) {
      setRedirectLoading(true)
    }

    // Try and create a subscripton with the vendor code as query parameter or
    // Navigate user to the account page if they have a access to fluentworlds product
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      productAccess &&
      !productAccess.fluentworlds &&
      isAuthenticated &&
      vendorCodeQueryParam !== undefined &&
      vendorCodeQueryParam !== ''
    ) {
      submitVendorCoupon(vendorCodeQueryParam)
    } else if (productAccess && productAccess.fluentworlds) {
      navigate(ACCOUNT_PATH)
    }
    // eslint-disable-next-line
  }, [productAccess, subscriptions])

  const handleInputChange = (e) => {
    setVendorCodeInput(e.currentTarget.value)
  }

  const submitVendorCoupon = async (vendorCodeIn) => {
    if (vendorCodeIn === undefined || vendorCodeIn === '') {
      return toast.error(t('invalidCode', { vendor: vendorName }))
    }

    // if creating the subscription using query param was unsucessful then show form for manual input
    const subscriptionCreated = await createStripeSubscription(
      SUBSCRIPTION_NAME,
      vendorCodeIn,
      vendorSourceName,
    )

    if (!subscriptionCreated) {
      // remove the loading state set when using the redirect
      setRedirectLoading(false)
    }
  }

  const mainContentLoading =
    auth0Loading ||
    subscriptionStateLoading ||
    userStateLoading ||
    redirectLoading ||
    (productAccess && productAccess.fluentworlds) ||
    !ready

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Header>
        <Global styles={GlobalStyles} />
        <TitleContainer>{vendorName}</TitleContainer>
        {userStateLoading || !ready ? (
          <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
        ) : (
          isAuthenticated && (
            <UserContainer>
              {t('greeting')} {username}
            </UserContainer>
          )
        )}
      </Header>
      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={8000}
        hideProgressBar={true}
        style={{ fontSize: '1.5rem' }}
      />
      {mainContentLoading ? (
        <Loading />
      ) : isAuthenticated ? (
        <>
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractPaymentAnimation}
              />
            </ShapeContainer>
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectDescription>
                {t('enterCode', { vendor: vendorName })}
              </StripeCheckoutRedirectDescription>
              <InputBox onChange={handleInputChange} />
              <StripeCheckoutRedirectButton
                onClick={() => submitVendorCoupon(vendorCodeInput.trim())}
              >
                {t('submit')}
              </StripeCheckoutRedirectButton>
              <Link
                css={`
                  width: 300px;
                  margin-top: 3em;
                `}
                to="/account"
              >
                <StripeCheckoutRedirectButton>
                  {t('goToAccount')}
                </StripeCheckoutRedirectButton>
              </Link>
            </StripeCheckoutContentContainer>
          </StripeCheckoutRedirectContainer>
        </>
      ) : (
        <LoginPrompt>
          {t('createAccountPrompt', { vendor: vendorName })}
          <StripeCheckoutRedirectButton
            css={'margin-top: 2rem;'}
            onClick={() =>
              loginWithRedirect({
                redirect_uri: auth0RedirectUri,
                mode: AUTH0_LOCK_SIGN_UP,
                ui_locales: language.code,
              })
            }
          >
            {t('signUp')}
          </StripeCheckoutRedirectButton>
        </LoginPrompt>
      )}
    </>
  )
}

export default PerpetualLicenseFWPage
