import React from 'react'

import PerpetualLicenseFWPage from 'components/perpetual-license'
import { SPANISH } from 'utils/supportedLanguages'

const Redeem = () => {
  return (
    <PerpetualLicenseFWPage
      redeemPath={'/ms-holdings/redeem/'}
      vendorSourceName={'MS_HOLDINGS'}
      queryParamKey={'ms-holdings-code'}
      vendorName={'MS Holdings'}
      language={SPANISH}
    />
  )
}

export default Redeem
